import React, { useState, useRef, useEffect } from 'react';
import { MdArrowDropDown } from "react-icons/md";
import { IoMdAddCircle } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Image from "../assets/lighthouse-logo-color.png"

import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [userOpen, setUserOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef(null);

  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  
  const toggleUserOPen = () => {
    setUserOpen(!userOpen);
  };
 

 

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  
  //   try {
  //     // Replace the URL with your actual API endpoint
  //     const response = await fetch('https://springboard.epictutor.net/api/device', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(formData),
  //     });
  //     console.log(formData, "form data submitted");
  
  //     if (response.ok) {
  //       // Handle successful response (e.g., show success message, redirect, etc.)
  //       console.log('Form submitted successfully!');
  //     } else {
  //       // Handle error response (e.g., show error message)
  //       console.error('Form submission failed:', response.statusText);
  //     }
  //   } catch (error) {
  //     console.error('Error submitting form:', error);
  //   }
  
  //   // Close the popup after submission, whether successful or not
  //   setShowPopup((prevShowPopup) => {
  //     if (prevShowPopup) {
  //       // Clear the form data
  //       setFormData({
  //         DeviceFName: '',
  //         AdminId: '',
  //         DeviceId: '',
  //         SerialNumber: '',
  //       });
  //     }
  //     return false; // Set the new state to false
  //   });
  // };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleAddDeviceClick = () => {
    setShowPopup(!showPopup);
  };

  return (
    <div className="">
      <nav className="bg-transparent border-b-4 border-gray-50 p-4">
        <div className="container mx-auto flex items-center justify-between">
          <div className=" ">
            <img src={Image} alt='image' />
          {/* <a
        href="#"
        className="text-green-500 gap-1 flex font-semibold"
        onClick={handleAddDeviceClick}
      >
        <IoMdAddCircle className='pt-1 h-5 w-5'/>
        Add New Device
          </a> */}
            </div>
          <div className="flex space-x-10 ">
            <Link to="/device" className="text-black font-semibold"
           
            >
              Device
            </Link>
            <div className="relative">
              <Link
                to="/employeetable"
                className="text-black flex font-semibold"
                
              >
                Employees 
              </Link>
             
            </div>
            

            <div className="relative">
              <a
                href="#"
                className="text-black flex font-semibold"
                onClick={toggleUserOPen}
              >
                User Managment <MdArrowDropDown className="h-7 w-7" />
              </a>
              {userOpen && (
                <div className="absolute bg-white text-gray-800 rounded-md mt-2 z-30 space-y-2 p-2">
                  <Link to="/uploadusertodevice" className="block px-1 py-2 font-semibold">
                   Upload User Data
                  </Link>
                  <Link to="/deleteusertodevice" className="block px-1 py-2 font-semibold">
                   Delete User Data
                  </Link>
                  <Link to="/employeeattendance" className="block px-1 py-2 font-semibold">
                   Attendance Log
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>


    </div>
  );
};

export default Navbar;
