
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { MaterialReactTable } from 'material-react-table';
import { AiFillDelete } from 'react-icons/ai'
import { FaRegEdit } from "react-icons/fa";
import Swal from 'sweetalert2'
import AddEmployee from '../Dashboard/AddEmployee';
import Dashboard from '../Dashboard/index';
import { FaDatabase } from "react-icons/fa";
import AddDevice from './AddDevice';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const DeviceShow = () => {
  const [employees, setEmployees] = useState([]);
  // const [isAddEmployeeModalOpen, setAddEmployeeModalOpen] = useState(false);
  // const [employeeId , setEmployeeId] = useState(null)
 


  const fetchData = async () => {
    try {
      const response = await axios.get('https://springboard.epictutor.net/api/device');
      const data = response.data;
      console.log(data.data)
      setEmployees(data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  



//   const handleDelete = (cell)=>{
//     console.log("Dealer",cell?.row?.original.SerialNumber)
//     console.log(employees)
//     Swal.fire({
//         title: 'Are you sure?',
//         text: "You won't be able to revert this!",
//         icon: 'warning',
//         showCancelButton: true,
//         confirmButtonColor: '#3085d6',
//         cancelButtonColor: '#d33',
//         confirmButtonText: 'Yes, delete it!'
//       }).then(async(result) => {
//         if (result.isConfirmed) {
//             try {
//               const apiKey = process.env.REACT_APP_API_KEY;
//                 const deleteUser = await fetch(`http://43.230.201.234:82/api/v2/WebAPI/DeleteBiometric?APIKey=${apiKey}&SerialNumber=${cell?.row?.original.SerialNumber}`,{
//                   method:"get",  
                
//                 })
//                 const response = await deleteUser?.json()
//                 toast.success('Device deleted successfully');
//                 fetchData();
                       
//             } catch (error) {
//                 console.log(error)
//             }
    
// }})
//   }

  // const handleAddEmployeeClick = () => {
  //   setAddEmployeeModalOpen(true);
  // };


  // Define columns for the MaterialReactTable
  const columns = [
    { accessorKey: 'DeviceFName', header: 'Device Name' },
      { accessorKey: 'DeviceModel', header: 'Device Modal' },
      { accessorKey: 'SerialNumber', header: 'Serial Number' },
      { accessorKey: 'UserCount', header: 'User Count' },
      { accessorKey: 'LastPing', header: 'Last Ping' },
    
  //   {
  //     header:"Action",
  //     size: 100,
  //     Cell:({cell})=>
  //       <>
  //       <button onClick={()=>handleDelete(cell)} className='px-1 ml-1 delay-75 duration-75 text-red-900 hover:text-red-700 py-1'><AiFillDelete size={20}/></button>
  //       </>  
  // },
  ];


  return (
    <>
    <div className='relative'>
    <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
    <Dashboard />

     <div className=" mx-auto p-8 relative">
      <h1 className="text-4xl font-bold mb-4">Device List</h1>

      {/* <div className="flex space-x-4 justify-end mb-4">
        

        <button
          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={handleAddEmployeeClick}
        >
          Add Device
        </button>
      </div> */}
      
        <MaterialReactTable
          data={employees}
          columns={columns}
          
          className="min-w-full bg-white border border-gray-300 shadow-sm"
        />

     

      {/* {isAddEmployeeModalOpen && (
          <AddDevice setAddEmployeeModalOpen={setAddEmployeeModalOpen} fetchData={fetchData}/>
        )}
       */}
    </div>
    </div>
    
   
    </>
  );
};

export default DeviceShow;
 