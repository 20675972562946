import './App.css';
import Login from './Loginpage/login';
import Dashboard from './Dashboard/index';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import EmployeeTable from './Dashboard/EmployeeTable';
import DeviceShow from './device/DeviceShow';
import EmployeeAttendance from './usermanagement/EmployeeAttendance';
import UploadUser from './usermanagement/UploadUser';
import Deleteuser from './usermanagement/Deleteuser';

function App() {
  return (
    <BrowserRouter>
      <div className="bg-slate-100 min-h-screen">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/employeetable" element={<EmployeeTable />} />
          <Route path="/device" element={<DeviceShow />} />
          <Route path='/employeeattendance' element={<EmployeeAttendance />} />
          <Route path='/uploadusertodevice' element={<UploadUser />} />
          <Route path='/deleteusertodevice' element={<Deleteuser />} />

        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
