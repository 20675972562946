import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";


import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  Button,
} from "@mui/material";
import axios from "axios";
import Dashboard from "../Dashboard/index";

function UploadUser() {
  const [selectedRows, setSelectedRows] = useState([]);
  const [devices, setDevices] = useState([]);
  const [devicePage, setDevicePage] = useState(0);
  const [deviceRowsPerPage, setDeviceRowsPerPage] = useState(10);

  const [devicesEmp, setDevicesEmp] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [employeePage, setEmployeePage] = useState(0);
  const [employeeRowsPerPage, setEmployeeRowsPerPage] = useState(10);

  const handleDevicePageChange = (event, newPage) => {
    setDevicePage(newPage);
  };

  const handleDeviceRowsPerPageChange = (event) => {
    setDeviceRowsPerPage(parseInt(event.target.value, 10));
    setDevicePage(0);
  };

  const handleEmployeePageChange = (event, newPage) => {
    setEmployeePage(newPage);
  };

  const handleEmployeeRowsPerPageChange = (event) => {
    setEmployeeRowsPerPage(parseInt(event.target.value, 10));
    setEmployeePage(0);
  };

  useEffect(() => {
    axios
      .get("https://springboard.epictutor.net/api/device")
      .then((response) => {
        setDevices(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching device data:", error);
      });

    axios
      .get("https://springboard.epictutor.net/api/employees")
      .then((response) => {
        setDevicesEmp(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching employee data:", error);
      });
  }, []);


  const handleCheckboxChange = (event, device) => {
    const selectedIndex = selectedRows.findIndex(
      (selectedDevice) => selectedDevice.DeviceId === device.DeviceId
    );
    let newSelected = [...selectedRows];

    if (selectedIndex === -1) {
      newSelected.push(device);
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    setSelectedRows(newSelected);
  };

  const isSelected = (device) => {
    return selectedRows.some(
      (selectedDevice) => selectedDevice.DeviceId === device.DeviceId
    );
  };

  const handleLogSelectedData = async () => {
    const selectedDevicesData = selectedRows.map((getdevice) => {
      return getdevice;
    });

    const selectedEmployeesData = selectedEmployees.map((employee) => {
      return employee;
    });

    console.log("All selected devices:", selectedDevicesData);
    console.log("All selected employees:", selectedEmployeesData);
    const promises = [];

    selectedDevicesData.forEach((device) => {
      selectedEmployeesData.forEach((employee) => {
        const promise = axios.post(
          "https://springboard.epictutor.net/api/sendpostrequest",
          null,
          {
            params: {
             
              empcode: employee.EmployeeCode,
              CardNumber: employee.stafflog?.CardNumber || "",
              empname: employee.EmployeeName,
              serialnumber: device.SerialNumber,
              VerifyMode: "1",
            },
          }
        );

        promises.push(promise);
      });
    });

    try {
      await Promise.all(promises);
      toast.success("Uploaded device successfully");
    } catch (error) {
      console.error("Error making API requests:", error);
      toast.error("Error in uploading device");
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelectedRows(devices);
    } else {
      setSelectedRows([]);
    }
  };

  const handleCheckboxEmployee = (event, employee) => {
    const selectedIndex = selectedEmployees.findIndex(
      (selectedEmp) => selectedEmp.EmployeeId === employee.EmployeeId
    );

    let newSelected = [...selectedEmployees];

    if (selectedIndex === -1) {
      newSelected.push(employee);
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    setSelectedEmployees(newSelected);
  };

  const isSelectedEmployee = (employee) => {
    return selectedEmployees.some(
      (selectedEmp) => selectedEmp.EmployeeId === employee.EmployeeId
    );
  };

  const handleSelectAllClickEmployee = (event) => {
    if (event.target.checked) {
      setSelectedEmployees(devicesEmp);
    } else {
      setSelectedEmployees([]);
    }
  };

  return (
    <>
      <div className="">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Dashboard />
        <div className="mx-auto p-8 relative bg-gray-200 ">
          <h1 className="text-4xl font-bold mb-8 font-3xl text-center">
            Upload User to Device
          </h1>

          <div className="w-full flex justify-between">
            <div className="w-[36%]">
            <Table className="bg-white rounded border-3 border-black">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    indeterminate={false}
                    checked={
                      selectedRows.length > 0 &&
                      selectedRows.length === devices.length
                    }
                    onChange={handleSelectAllClick}
                     sx={{ marginLeft: '-11px' }}
                  />
                </TableCell>
                <TableCell>Device Name</TableCell>
                <TableCell>Serial Number</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {devices
                .slice(
                  devicePage * deviceRowsPerPage,
                  devicePage * deviceRowsPerPage + deviceRowsPerPage
                )
                .map((device) => (
                  <TableRow
                    key={device.DeviceId}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      
                    
                    }}
                    selected={isSelected(device)}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isSelected(device)}
                        onChange={(event) =>
                          handleCheckboxChange(event, device)
                        }
                      />
                    </TableCell>
                    <TableCell>{device.DeviceFName}</TableCell>
                    <TableCell>{device.SerialNumber}</TableCell>
                  </TableRow>
                ))}
              <TableRow>
                <TableCell colSpan={3}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={devices.length}
                    rowsPerPage={deviceRowsPerPage}
                    page={devicePage}
                    onPageChange={handleDevicePageChange}
                    onRowsPerPageChange={handleDeviceRowsPerPageChange}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
            </Table>
            </div>

            <div className="w-[62%]">
            <Table className="bg-white rounded">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    indeterminate={false}
                    checked={
                      selectedEmployees.length > 0 &&
                      selectedEmployees.length === devicesEmp.length
                    }
                    onChange={handleSelectAllClickEmployee}
                    sx={{ marginLeft: '-11px' }}

                  />
                </TableCell>
                <TableCell className="font-semibold text-sm">
                  Employee Code
                </TableCell>
                <TableCell>Employee Name</TableCell>
                <TableCell>Card Number</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {devicesEmp
                .slice(
                  employeePage * employeeRowsPerPage,
                  employeePage * employeeRowsPerPage + employeeRowsPerPage
                )
                .map((employee) => (
                  <TableRow
                    key={employee.EmployeeId}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                    selected={isSelectedEmployee(employee)}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isSelectedEmployee(employee)}
                        onChange={(event) =>
                          handleCheckboxEmployee(event, employee)
                        }
                      />
                    </TableCell>
                    <TableCell>{employee.EmployeeCode}</TableCell>
                    <TableCell>{employee.EmployeeName}</TableCell>
                    <TableCell>
                      {employee.stafflog?.CardNumber || ""}
                    </TableCell>
                  </TableRow>
                ))}
              <TableRow>
                <TableCell colSpan={4}>
                 
                  <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={devicesEmp.length}
                      rowsPerPage={employeeRowsPerPage}
                      page={employeePage}
                      onPageChange={handleEmployeePageChange}
                      onRowsPerPageChange={handleEmployeeRowsPerPageChange}
                      className="w-full"
                      ActionsComponent={(props) => (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <IconButton
                            onClick={() => handleEmployeePageChange(null, employeePage - 1)}
                            disabled={employeePage === 0}
                          >
                            <KeyboardArrowLeftIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => handleEmployeePageChange(null, employeePage + 1)}
                            disabled={
                              employeePage ===
                              Math.ceil(devicesEmp.length / employeeRowsPerPage) - 1
                            }
                          >
                            <KeyboardArrowRightIcon />
                          </IconButton>
                        </div>
                      )}
                    />

                </TableCell>
              </TableRow>
            </TableBody>
            </Table>
            </div>
          </div>
        </div>
        <div className='text-right pb-6 pr-[2rem] bg-gray-200'>
            <button
              type='button'
              onClick={handleLogSelectedData}
              className="bg-green-600 text-white py-2 px-4 rounded mt-2 font-semibold"
            >
              Upload Device
            </button>
          </div>

      </div>
    </>
  );
}

export default UploadUser;
