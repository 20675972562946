import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import userCredentials from "./userCredentials.json"; // Adjust the path based on your project structure
import Image from "../assets/lighthouse-logo-color.png"
export default function Login() {
  const Navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [resetEmail, setResetEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  function validateResetForm() {
    return resetEmail.length > 0 && currentPassword.length > 0 && newPassword.length > 0;
  }

  function handleLogin(event) {
    event.preventDefault();

    const user = userCredentials.users.find(
      (user) => user.email === email && user.password === password
    );

    if (user) {
      // Successful login
      setLoginSuccess(true);
      setError("");
      Navigate("/employeetable");
    } else {
      // Failed login
      setLoginSuccess(false);
      setError("Invalid username or password");
    }
  }

  function handleForgotPassword(event) {
    event.preventDefault();

    // Mocking a reset password process
    const foundUser = userCredentials.users.find(
      (user) => user.email === resetEmail && user.password === currentPassword
    );

    if (foundUser) {
      // Workaround to modify the password for demonstration purposes
      const updatedUsers = userCredentials.users.map((user) =>
        user.email === resetEmail ? { ...user, password: newPassword } : user
      );

      // Update the imported JSON data (this won't work in a real backend scenario)
      userCredentials.users = updatedUsers;

      // Now, automatically log in the user with the new password
      setEmail(resetEmail);
      setPassword(newPassword);
      handleLogin(event);

      setResetSuccess(true);
      setForgotPassword(false); // Reset to login mode after successful password reset
      setError("");
    } else {
      setResetSuccess(false);
      setError("Invalid username or current password for password reset");
    }
  }


  return (
    <div className="min-h-screen flex items-center justify-center relative bg-gray-200">
      <img src={Image} className="absolute z-50 top-20 h-10" />
    <form
      onSubmit={forgotPassword ? handleForgotPassword : handleLogin}
      className="bg-white shadow-md rounded-xl px-8 pt-6 pb-8 mb-4"
    >
        {forgotPassword ? (
          <>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="resetEmail">
                Email<span className="text-red-500">*</span>
              </label>
              <input
                className="shadow appearance-none border rounded w-[20rem] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="resetEmail"
                type="text"
                placeholder="Enter your email"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="currentPassword">
                Current Password<span className="text-red-500">*</span>
              </label>
              <input
                className="shadow appearance-none border rounded w-[20rem] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="currentPassword"
                type="password"
                placeholder="Enter your current password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="newPassword">
                New Password<span className="text-red-500">*</span>
              </label>
              <input
                className="shadow appearance-none border rounded w-[20rem] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="newPassword"
                type="password"
                placeholder="Enter your new password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            {resetSuccess && (
              <p className="text-green-500 mb-4">
                Password reset successful! Check your email for the new password.
              </p>
            )}
          </>
        ) : (
          <>
            <div className="mb-4">
              <label className="block text-gray-700 text-xl font-bold mb-2" htmlFor="email">
                Username<span className="text-red-500">*</span>
              </label>
              <input
                className="shadow appearance-none border w-[20rem] rounded  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                type="text"
                placeholder="username"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-xl font-bold mb-2" htmlFor="password">
                Password<span className="text-red-500">*</span>
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="password"
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            {error && <p className="text-red-500 mb-4">{error}</p>}
            {loginSuccess && <p className="text-green-500 mb-4">Login successful!</p>}
          </>
        )}

          <button
          className={`bg-blue-500 text-white font-bold py-2 px-4 rounded ${
            forgotPassword
              ? !validateResetForm() && "opacity-50 cursor-not-allowed"
              : !validateForm() && "opacity-50 cursor-not-allowed"
          }`}
          type="submit"
          disabled={forgotPassword ? !validateResetForm() : !validateForm()}
        >
          {forgotPassword ? "Reset Password" : "Login"}
        </button>

        {/* {!forgotPassword && (
          <p
            className="mt-2 text-sm text-blue-500 cursor-pointer"
            onClick={() => setForgotPassword(true)}
          >
            Forgot Password?
          </p>
        )} */}

      </form>
      <div className="absolute z-50 bottom-10 text-sm font-semibold">@2024 All Copyright Reserved. LiveLake Education. </div>
    </div>
  );
}

