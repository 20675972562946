

import React, { useState, useEffect } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import Dashboard from "../Dashboard/index"
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EmployeeAttendance = () => {
  const [apiData, setApiData] = useState([]);
  const [showpopup, setShowpopup] = useState(false)
  const [device, setDevice] = useState([]);
  const [inputData, setInputData] = useState({
    currentDate: '',
    previousDate: '',
    selectedDevice: '',
  })


  const fetchData = async () => {
    try {
      const response = await axios.get('https://springboard.epictutor.net/api/device');
      const data = response.data;
      console.log(data.data)
      setDevice(data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  const handleImportExcelClick = () => {
    // Set the state to open the modal or popup for importing Excel
    setShowpopup(true);
  };



  // const columns = [

  //   { accessorKey: 'SerialNumber', header: 'Serial Number', size: 100 },
  //   { accessorKey: 'EmployeeCode', header: 'Employee Code', size: 100 },
  //   { accessorKey: 'LogDate', header: 'Log data ', size: 150 },
  // ]

  const columns = [
    { accessorKey: 'sno', header: 'S.No', size: 50 },
    { accessorKey: 'EmployeeName', header: 'Employee Name', size: 100 },
    { accessorKey: 'SerialNumber', header: 'Serial Number', size: 100 },
    { accessorKey: 'EmployeeCode', header: 'Employee Code', size: 100 },
    { accessorKey: 'LogDate', header: 'Log data', size: 150 },
    { accessorKey: 'Present', header: 'Present', size: 100, fixed: true },
  ];

  const handleFindLog = async (e) => {
    e.preventDefault();
    // console.log("inputData" ,inputData);
    try {
      const response = await axios.post(
        'https://springboard.epictutor.net/api/logs',
        null,
        {
          params: {
            currentDateTime: inputData.currentDate,
            prevDateTime: inputData.previousDate,
            serialnumber: inputData.selectedDevice,
          },
        }
      );


      setApiData(response.data.data);

      toast.success('Attendance Logs Data');
      setShowpopup(false);
      setInputData({
        currentDate: '',
        previousDate: '',
        selectedDevice: '',
      });
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('No Attendance Logs Data');
    }
  };

// console.log(apiData ,"hello")

  const handleInputChange = (name, value) => {
    setInputData({ ...inputData, [name]: value });
  };


  const handleExportData = () => {
    if (apiData.length === 0) {
      toast.error('No data available for export');
      return;
    }
  
    // Define the CSV headers
    const headers = ['S.No', 'Employee Name', 'Serial Number', 'Employee Code', 'Log Date', 'Present'];
  
    // Build CSV content with headers
    const csvRows = [
      headers.join(','), // Add headers row
      ...apiData
        .filter(item => item.EmployeeName && item.EmployeeName.trim() !== "") // Filter rows with non-empty EmployeeName
        .map((row, index) => [
          index + 1, // S.No
          row.EmployeeName, 
          row.SerialNumber, 
          row.EmployeeCode, 
          row.LogDate, 
          'Yes' // Present column
        ].join(',')) // Convert each row to CSV string
    ];
  
    // Join rows into a single CSV string
    const csvContent = csvRows.join('\n');
  
    // Create a Blob and set it as a download link
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'exportedData.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  
    toast.success('Data exported successfully');
  };
  
  return (
    <div className="">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Dashboard />
      <div className='pl-7 text-3xl font-bold pb-6 mt-12'>Employee Device Logs</div>
      <div className="flex mr-[4rem] justify-end mb-4">
        <button onClick={handleExportData} className='text-black mr-[1rem] bg-blue-300 hover:bg-blue-400 w-[10rem] h-10 rounded font-bold '>Download All Data</button>
        <button
          className="bg-green-600 hover:bg-green-800 text-white font-bold py-2 px-4 rounded"
          onClick={handleImportExcelClick}
        >
          Filter By Date
        </button>
      </div>

      {showpopup && (
        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
          <div className="bg-white p-4 rounded shadow-md" style={{ width: '30%', zIndex: 50 }}>
            <div className='flex justify-end' onClick={() => setShowpopup(false)}>
              <span className='text-neutral-50 text-2xl hover:bg-red-700 cursor-pointer delay-75 duration-75 bg-red-500 px-3 py-1 rounded-full'>X</span>
            </div>
            <h3 className="text-xl mt-[-2rem] font-bold mb-4">Date wise Log Data</h3>


            <form onSubmit={handleFindLog}>
              <div className=" flex flex-col justify-center p-1 ">
                <label className="block font-semibold font-opensans">Device<span className="text-red-500">*</span></label>
                <select
                  required
                  value={inputData.selectedDevice} // Bind selectedDevice state
                  onChange={(e) => handleInputChange('selectedDevice', e.target.value)}
                  className="border w-full border-neutral-800 p-2 mt-1"
                >
                  <option value="" disabled selected className="text-gray-400 font-opensans">Select Device</option>
                  {device.map((deviceItem) => (
                    <option key={deviceItem.id} value={deviceItem.SerialNumber}>{deviceItem.SerialNumber} ({deviceItem.DeviceFName})</option>
                  ))}
                </select>
              </div>
              <div className=' text-left px-1 py-1  justify-center' style={{ display: 'flex', flexDirection: 'column' }}>
                <label className='block font-opensans font-semibold'>
                  Previous Date<span className='text-red-500'>*</span>
                </label>
                <input
                  type='date'
                  name='previousDate'
                  placeholder='Previous Date'
                  value={inputData.previousDate}
                  onChange={(e) => handleInputChange('previousDate', e.target.value)}
                  className={`border w-full border-neutral-800 p-2 my-1`}
                />
              </div>
              <div className=' text-left px-1 py-1  justify-center' style={{ display: 'flex', flexDirection: 'column' }}>
                <label className='block font-opensans font-semibold'>
                  Current Date<span className='text-red-500'>*</span>
                </label>
                <input
                  type='date'
                  name='currentDate'
                  placeholder='Current Date'
                  value={inputData.currentDate}
                  onChange={(e) => handleInputChange('currentDate', e.target.value)}
                  className={`border w-full border-neutral-800 p-2 my-1`}
                />
              </div>


              <button
                type='submit'
                className="bg-blue-500 text-white py-2 px-4 rounded mt-2"
              >
                Submit
              </button>
            </form>



          </div>
        </div>
      )}
      <div className='pl-7 pr-7 relative'>


        {/* <MaterialReactTable
          data={apiData}
          columns={columns}
          className="min-w-full bg-white border border-gray-300 shadow-sm"
        /> */}
        {/* <MaterialReactTable
        data={apiData.map((item, index) => ({

          ...item,
          sno: index + 1, 
          Present: 'Yes' 
        }))}
        columns={columns}
        className="min-w-full bg-white border border-gray-300 shadow-sm"
      /> */}
        <MaterialReactTable
          data={apiData
            .filter(item => item.EmployeeName && item.EmployeeName.trim() !== "")
            .map((item, index) => ({
              ...item,
              sno: index + 1,
              Present: 'Yes'
            }))
          }

          columns={columns}
          className="min-w-full bg-white border border-gray-300 shadow-sm"
        />


        {/* <>
          <div className='pt-2 absolute z-20 pl-4'>
            <button onClick={handleExportData} className='text-black  bg-blue-300 hover:bg-blue-400 w-[10rem] h-10 rounded font-bold '>Download All Data</button>
            </div>
            <MaterialReactTable data={apiData} className="border border-gray-600" />
          </> */}

      </div>
    </div>
  );
};

export default EmployeeAttendance;
